import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find.js";
import _ from 'lodash';
import { renderMarkdown } from '~/functions/markdown';
import { useUpdateSelectedOptionsMutation } from '~/types/types';
export default defineComponent({
  inheritAttrs: false,
  props: {
    userInput: {
      type: Object,
      default: function _default() {}
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {
    var _useUpdateSelectedOpt = useUpdateSelectedOptionsMutation(),
      updateSelectedOptions = _useUpdateSelectedOpt.mutate;
    return {
      updateSelectedOptions: updateSelectedOptions
    };
  },
  data: function data() {
    return {
      renderMarkdown: renderMarkdown
    };
  },
  computed: {
    value: function value() {
      var values = this.$props.userInput.selectedOptions.map(function (e) {
        return e.id;
      });
      return this.isMultiple ? values : values[0];
    },
    translatedOptions: function translatedOptions() {
      var _this = this;
      return this.options.map(function (e) {
        return _this.translateOption(e);
      });
    }
  },
  methods: {
    translateOption: function translateOption(e) {
      var translatedOption = _objectSpread({}, e);
      if (e.ixId) {
        var keyForLabel = 'database.option.' + e.ixId + '.label';
        var translatedLabel = this.$t(keyForLabel);
        if (translatedLabel !== keyForLabel) translatedOption.label = translatedLabel;
        var keyForSubLabel = 'database.option.' + translatedOption.ixId + '.sublabel';
        var translatedSubLabel = this.$t(keyForSubLabel);
        if (translatedSubLabel !== keyForSubLabel) translatedOption.sublabel = translatedSubLabel;
        var keyForTooltip = 'database.option.' + e.ixId + '.tooltip';
        var translatedTooltip = this.$t(keyForTooltip);
        if (translatedTooltip !== keyForTooltip) translatedOption.tooltip = translatedTooltip;
      }
      return translatedOption;
    },
    valueChange: function valueChange(value) {
      var values;
      if (_.isArray(value)) values = value.filter(function (e) {
        return e !== null && e !== undefined;
      });else if (value !== null && value !== undefined) values = [value];else values = [];
      var noneOption = this.options.find(function (e) {
        return e.label === 'None of the above';
      });
      if (noneOption !== undefined) {
        if (values.find(function (e) {
          return e === noneOption.id;
        })) {
          values = values.filter(function (e) {
            return e === noneOption.id;
          });
        }
      }
      this.updateSelectedOptions({
        userInputId: this.$props.userInput.id,
        values: values
      });
    }
  }
});